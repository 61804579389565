import { Link } from 'gatsby';
import React from 'react';
import { AiFillGithub } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';

const classes = {
  wrapper: 'mb-6',
  name: 'font-semibold text-gray-900 dark:text-white pb-1',
  description: 'text-md text-gray-600 dark:text-gray-400 font-light',
  date: 'text-sm text-gray-500 font-light',
  list: 'uppercase tracking-wider',
  item: 'inline list-none pr-3',
  link:
    'inline-block py-2 font-semibold text-gray-600 text-2xl hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-500',
};

const SummaryItem = ({
  name,
  description,
  link = false,
  date = '',
  internal = false,
  github,
  hideLinkIcon = false,
}) => {
  let linkContent;
  if (internal) {
    linkContent = <Link to={link}>{name}</Link>;
  } else {
    linkContent = <a href={link}>{name}</a>;
  }

  return (
    <div className={classes.wrapper}>
      <h3
        className={`${classes.name} ${
          link ? 'hover:underline hover:text-black' : ''
        }`}
      >
        {link ? linkContent : name}
      </h3>
      <p className={classes.description}>{description}</p>
      {date && <span className={classes.date}>{date}</span>}
      <ul className={classes.list}>
        {github && (
          <li className={classes.item}>
            <a className={classes.link} href={github}>
              <AiFillGithub />
            </a>
          </li>
        )}
        {!hideLinkIcon && link && (
          <li className={classes.item}>
            <a className={classes.link} href={link}>
              <FiExternalLink />
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default SummaryItem;
