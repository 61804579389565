import React, { useState } from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const classes = {
  sectionReset: '',
  input: 'w-full bg-transparent block mb-4 px-2 py-2 outline-none border-b hover:border-b-1 focus:border-b-1 max-w-2xl',
};

const ProjectsList = ({ projects }) => {
  return (
    <Section
      title="Hobby Projects"
      className={classes.sectionReset}
    >
      {projects.map((project) => (
        <SummaryItem
          key={project.name}
          name={project.name}
          description={project.description}
          link={project.link}
          github={project.github}
        />
      ))}
    </Section>
  );
};

export default ProjectsList;
